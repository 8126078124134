.nw-theme {
  --footer-color: black;
  --header-color: white;
  --background-color: #f6f6f6;
  --header-shadow: 0 5px 5px lightgrey;
  --table-border: 1.5rem #f6f6f6 solid;
  --primary-color: #571060;
  --button-hover-color: #703377;
  --headerLink-hover-color: #703377;
  --hover-text-color: white;
  --info-color: #571060;
  --list-border-color: rgb(214, 214, 214);
  --btn-text-transform: none;
  --btn-radius: .25rem;
  --font-family: "CalibreWeb";
  --btn-border-width: .15rem;
  --btn-font-size: 1rem;
  --title-font: "CalibreBlack";
}

.pns-theme {
  --footer-color: black;
  --header-color: #ffd600;
  --background-color: white;
  --header-shadow: 0px 2px 0px 0px black;
  --table-border: 1.5rem white solid;
  --primary-color: #231f20;
  --button-hover-color: #ffd600;
  --headerLink-hover-color: #000000;
  --hover-text-color: #231f20;
  --info-color: #231f20;
  --list-border-color: #231f20;
  --btn-text-transform: none;
  --btn-radius: unset;
  --font-family: "AkzidenzGroteskBE-XBdCn","Calibre",sans-serif;
  --title-font: "AkzidenzGroteskBE-XBdCn";
  --btn-border-width: .15rem;
  --btn-font-size: 1rem;
}

@font-face {
  font-family: "AkzidenzGroteskBE-XBdCn";
  src: url("fonts/AkzidenzGroteskBE-XBdCn.eot");
  src: url("fonts/AkzidenzGroteskBE-XBdCn.eot?#iefix")
  format("embedded-opentype"),
  url("fonts/AkzidenzGroteskBE-XBdCn.woff2") format("woff2"),
  url("fonts/AkzidenzGroteskBE-XBdCn.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "CalibreBlack";
  src: url("fonts/CalibreWeb-Black.eot");
  src: url("fonts/CalibreWeb-Black.eot?#iefix")
  format("embedded-opentype"),
  url("fonts/CalibreWeb-Black.woff2") format("woff2"),
  url("fonts/CalibreWeb-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "CalibreWeb";
  src: url("fonts/CalibreWeb-Medium.eot");
  src: url("fonts/CalibreWeb-Medium.eot?#iefix")
  format("embedded-opentype"),
  url("fonts/CalibreWeb-Medium.woff2") format("woff2"),
  url("fonts/CalibreWeb-Medium.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

body, button {
  font-family: var(--font-family);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#root {
  min-width: 335px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cardContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 100vh;
  background-color: var(--background-color);
  border: none;
}

.cardHeader {
  width: 100%;
  background-color: var(--header-color);
  border: none;
  height: 4.5rem;
  min-height: 4.5rem;
  display: flex;
  box-shadow: var(--header-shadow);
  align-items: center;
  align-content: space-between;
}

.cardHeader .row {
  width: 100%;
  align-items: center;
}

.cardFooter {
  width: 100%;
  background-color: var(--footer-color);
  border: none;
  min-height: 10vh;
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;

}

.cardFooter .row {
  width: 100%;
}

.footerLink {
  color: white;
  font-weight: 700;
}

.headerLink {
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 600;
}

.headerLink:hover {
  text-decoration: underline;
  color: var(--headerLink-hover-color);
}

.headerLogo {
  max-height: 100%;
}

.cardTable {
  background-color: var(--background-color);
  padding-top: 3rem;
}

.cardTable tr {
  background-color: white;
}

.cardTable td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: var(--table-border);
  vertical-align: middle;
}

.card-body {
  display: block;
  min-width: 50vw;
  padding-top: 2rem;
}

.left {
  float: left;
  text-align: left;
  word-break: break-word;
}

.right {
  float: right;
  text-align: right;
}

.center {
  text-align: center;
  display: block;
}

.cardNum {
  color: grey;
  font-size: .9rem;
  width: 100%;
}

.cardForm {
  text-align: left;
  display: inline-block;
  width: 100%;
  max-width: 620px;
}

.expiryDate {
  display: block;
  padding-left: unset;
}

label {
  font-size: .9rem;
  margin-bottom: unset;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active
.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show>.btn-light.dropdown-toggle:focus
{
  background-color: unset;
  border: unset;
  box-shadow: none;
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show>.btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.info, .alert-icon {
  width: 1rem;
  display: inline-block;
}

.info path[fill] {
  fill: var(--info-color);
}

.alert {
  margin-top: 1.5rem;
}

.btn-primary, .btn-primary:disabled, .btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  font-weight: 600;
  padding: .5rem 3.5rem .5rem 3.5rem;
  text-transform: var(--btn-text-transform);
  border-radius: var(--btn-radius);
}

.btn-primary:hover {
  background-color: var(--button-hover-color);
  border-color: var(--button-hover-color);
  color: var(--hover-text-color);
  text-transform: var(--btn-text-transform);
  border-radius: var(--btn-radius);
}

.btn-primary:disabled:hover {
  cursor: not-allowed;
}

.btn-primary:focus {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  font-weight: 600;
  text-transform: var(--btn-text-transform);
  border-radius: var(--btn-radius);
}

.btn-secondary, .btn-secondary:hover {
  border: none;
  background-color: unset;
  color: var(--primary-color);
  font-size: .9rem;
  font-weight: 600;
  text-transform: var(--btn-text-transform);
  border-radius: var(--btn-radius);
}

.btn-outline-primary, .btn-outline-primary:not(:disabled):not(.disabled):active  {
  background-color: unset;
  border-color: var(--primary-color);
  color: var(--primary-color);
  font-weight: 700;
  font-size: var(--btn-font-size);
  padding: .5rem 1.2rem .5rem 1.2rem;
  text-transform: var(--btn-text-transform);
  border-radius: var(--btn-radius);
  border-width: var(--btn-border-width);
}

.btn-outline-primary:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
  font-weight: 700;
  font-size: var(--btn-font-size);
  text-transform: var(--btn-text-transform);
  border-radius: var(--btn-radius);
  border-width: var(--btn-border-width);
}

.half {
  width: 50%;
}

.thin {
  font-weight: 300;
}

.modal-footer {
  border-top: none;
}
.close:focus {
  border: 0px solid #fff;
  outline: none;
}

.card-image {
  width: 58px;
  height: 37px;
}

@media (min-width: 576px) {

  .cardForm {
    padding: 3rem;
  }

  .list-group-item:last-child {
    border-right:1px solid rgba(0,0,0,.125);
    border-color: var(--list-border-color);
    border-radius: var(--btn-radius);
  }

  .list-group-item {
    border-right: none;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-color: var(--list-border-color);
    border-radius: unset;
  }

  .list-group {
    padding-bottom: 1rem;
    border-radius: var(--btn-radius);
    font-family: var(--font-family);
  }

  .list-group-horizontal-sm, .list-group-horizontal-sm:last-child {
    border-radius: var(--btn-radius);
    border-top-right-radius: var(--btn-radius);
    border-top-left-radius: unset;
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    border-radius: var(--btn-radius);
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: var(--btn-radius);
    border-bottom-left-radius: var(--btn-radius);
  }
}

.row {
  margin: unset;
}

.col-3 {
  padding-right: 16px;
  padding-left: 16px;
}

.container-fluid {
  border: 2px solid var(--list-border-color);
  border-radius: var(--btn-radius);
  margin-bottom: 1.5rem;
  background-color: white;
  padding: .5rem 0;
}

.col-6, .col-3, .col-4 {
  padding-right: unset;
}

.modal-content {
  font-family: var(--font-family);
  font-weight: 700;
  border-radius: var(--btn-radius);
}

.modal-header {
  padding: 0rem .5rem;
  border-bottom: none;
}

.modal-body {
  font-weight: 900;
  font-size: 1.5rem;
  font-family: var(--title-font);
}

.form-control {
  border-color: var(--list-border-color);
  border-radius: var(--btn-radius);
  min-height: 48px;
}

.title {
  font-family: var(--title-font);
}

.listValid {
  text-align: right;
}

.removeButton {
  max-width: 100px;
}

@media screen and (min-width: 1351px) and (max-width: 1550px) {
  .col-lg-6 {
    max-width: 48%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1350px) {
  .col-lg-6 {
    max-width: 45%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .col-lg-6 {
    max-width: 43%;
  }
}

@media screen and (max-width: 991px) {

  .listValid {
    text-align: right;
    padding-right: 16px;
    flex: 0 0 40%;
    max-width: unset;
  }

  .removeButton {
    width: 100%;
    max-width: unset;
  }

  .cardForm {
    min-width: unset;
    padding-top: 20px;
  }

  .expiryDate {
    padding-right: unset;
  }

  #typeImage {
    max-width: 10%;
  }
}

@media (max-width: 576px) {
  .cardFooter .row .left {
    text-align: center;
  }

  .cardFooter .row .right {
    text-align: center;
  }

  .modal-backdrop {
    min-width: 335px;
  }

  #cardName {
    padding-left: 12%;
  }
}
